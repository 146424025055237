<template>
  <div class="">
    <div class="text-2xl font-bold flex justify-center pb-8">
      Hi there, please log in
    </div>

    <div class="w-80">
      <div>
        <label
          for="email"
          class="block text-sm font-medium text-gray-700"
        >EMAIL</label>

        <div class="mt-1">
          <input
            type="email"
            name="email"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="you@example.com"
          >
        </div>
      </div>

      <div class="pt-5">
        <label
          for="password"
          class="block text-sm font-medium text-gray-700"
        >PASSWORD</label>

        <div class="mt-1">
          <input
            type="password"
            name="password"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Password"
          >
        </div>
      </div>

      <div class="flex items-center justify-between pt-2">
        <div class="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          >
          <label
            for="remember-me"
            class="ml-2 block text-sm text-gray-900"
          > Remember me </label>
        </div>

        <div class="text-sm">
          <router-link
            :to="{ name: 'ResetPassword' }"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Forgot your password?
          </router-link>
        </div>
      </div>

      <div class="pt-5">
        <button
          type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Sign in
        </button>
      </div>
    </div>

    <div class="pt-4 flex justify-center items-center">
      Need a fexploit account?
      <router-link
        :to="{ name: 'Register' }"
        class="font-medium text-indigo-600 hover:text-indigo-500 pl-2"
      >
        Create an account
      </router-link>
    </div>
  </div>
</template>